import { Message, TransitionablePortal } from "semantic-ui-react";

const Alert = (props) => {
  const { open, message, error, success } = props;
  return (
    <TransitionablePortal
      open={open}
      transition={{ animation: "drop", duration: 500 }}
    >
      <div
        style={{
          position: "fixed",
          zIndex: 9999,
          width: "250px",
          left: "calc(50% - 125px)",
          top: "calc(50%)",
        }}
      >
        <Message content={message} error={error} success={success} />
      </div>
    </TransitionablePortal>
  );
};
export default Alert;
