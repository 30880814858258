import { useEffect, useState } from "react";
import { USERS, CREATE_USER, UPDATE_USER, CIRCUITS } from "../graphQueries";
import { useLazyQuery, useMutation } from "@apollo/client";
import { Table, Loader, Button, Header, Label, Popup } from "semantic-ui-react";
import PageModal from "../PageModal/PageModal";
import AddEditUser from "./AddEditUser/AddEditUser";
import Alert from "../Alert/Alert";
import moment from "moment";

const Users = () => {
  const [usersList, setUsersList] = useState([]);
  const [initializing, setInit] = useState(true);
  const [isAddEditUser, setAddEditUser] = useState(false);
  const [selectedUser, setSelectedUser] = useState({});
  const [mutating, setMutating] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [circuitsList, setCircuitsList] = useState([]);
  const [mode, setMode] = useState("add");
  const [sort, setSort] = useState({
    column: "createdAt",
    order: "descending",
  });

  useEffect(() => {
    users();
    circuits();
  }, []);

  useEffect(() => {
    if (usersList.length && circuitsList.length) {
      setInit(false);
    }
  }, [usersList, circuitsList]);

  const [users] = useLazyQuery(USERS, {
    fetchPolicy: "network-only",
    onCompleted: (result) => handleUsers_Result(result),
    onError: (error) => handleUsers_Error(error),
  });

  const handleUsers_Result = (result) => {
    console.log(result);
    if (result.users) {
      setUsersList(result.users);
    }
  };

  const handleUsers_Error = (error) => {
    console.log(error);
  };

  const [circuits] = useLazyQuery(CIRCUITS, {
    fetchPolicy: "network-only",
    onCompleted: (result) => handleCircuits_Result(result),
    onError: (error) => handleCircuits_Error(error),
  });

  const handleCircuits_Result = (result) => {
    console.log(result);
    if (result && result.circuits) {
      let newArray = result.circuits.map((item) =>
        Object.assign({}, item, {
          text: item.circuit,
          value: item.circuitId,
        })
      );
      setCircuitsList(newArray);
    }
  };

  const handleCircuits_Error = (error) => {
    console.log(error);
  };

  const handleChangeField = (e, data) => {
    const key = data.id;
    let value = e.target.value;
    if (data.id === "role" || data.id === "circuitId") value = data.value;
    let obj = { ...selectedUser };
    Object.assign(obj, { [key]: value });
    setSelectedUser(obj);
  };

  const handleAddUser = () => {
    setMutating(true);
    if (selectedUser.email && selectedUser.role) {
      createUser({
        variables: { email: selectedUser.email, role: selectedUser.role },
      });
    } else {
      setMutating(false);
      setErrorMessage("Please fill out all required fields.");
      setTimeout(() => {
        setErrorMessage("");
      }, 3000);
    }
  };

  const [createUser] = useMutation(CREATE_USER, {
    onCompleted: (result) => handleCreateUser_Result(result),
    onError: (error) => handleCreateUser_Error(error),
  });

  const handleCreateUser_Result = (result) => {
    console.log(result);
    if (result.createUser.status === "succeeded") {
      console.log("inside result");
      setSuccessMessage("User created successfully.");
      users();
      resetState();
    } else {
      setErrorMessage("Error creating user.");
      resetState();
    }
  };

  const handleCreateUser_Error = (error) => {
    console.log(error);
    setErrorMessage(error.message);
    resetState();
  };

  const resetState = () => {
    setTimeout(() => {
      setMutating(false);
      setAddEditUser(false);
      setErrorMessage("");
      setSuccessMessage("");
      setSelectedUser("");
    }, 3000);
  };

  const handleSetAddUser = () => {
    setSelectedUser({});
    setAddEditUser(true);
    setMode("add");
  };

  const handleSetEditUser = () => {
    setAddEditUser(true);
    setMode("edit");
  };

  const handleUpdateUser = () => {
    setMutating(true);
    console.log(selectedUser);
    updateUser({ variables: selectedUser });
  };

  const [updateUser] = useMutation(UPDATE_USER, {
    onCompleted: (result) => handleUpdateUser_Result(result),
    onError: (error) => handleUpdateUser_Error(error),
  });

  const handleUpdateUser_Result = (result) => {
    console.log(result);
    if (result.updateUser.status === "succeeded") {
      setSuccessMessage("User updated successfully!");
      users();
      resetState();
    }
    if (result.updateUser.status === "deleted") {
      setSuccessMessage("User deleted successfully!");
      users();
      resetState();
    }

    if (result.updateUser.status === "failed") {
      setErrorMessage(result.updateUser.message || "Unable to update user");
      users();
      resetState();
    }
  };

  const handleUpdateUser_Error = (error) => {
    console.log(error);
    setErrorMessage(error.message);
    resetState();
  };

  const handleDisableUser = () => {
    const obj = { ...selectedUser };
    Object.assign(obj, { status: "Disabled" });
    updateUser({ variables: obj });
  };

  const handleDeleteUser = () => {
    const obj = { ...selectedUser };
    Object.assign(obj, { status: "Deleted" });
    updateUser({ variables: obj });
  };

  const getEditDisabled = () => {
    if (selectedUser.userId) return false;
    else return true;
  };

  const getStatusColumn = (status) => {
    if (status === "Active") {
      return <Label color="green" content="Active" />;
    }
    if (status === "Disabled") {
      return <Label color="orange" content="Disabled" />;
    }
    if (status === "Pending") {
      return <Label color="blue" content="Pending" />;
    }
  };

  const handleSort = (column) => {
    const newOrder = sort.order === "ascending" ? "descending" : "ascending";
    let sortList = [...usersList];
    if (newOrder === "ascending") {
      sortList.sort((a, b) => (a[column] || "").localeCompare(b[column] || ""));
    }
    if (newOrder === "descending") {
      sortList.sort((a, b) => (b[column] || "").localeCompare(a[column] || ""));
    }
    setUsersList(sortList);
    setSort({ column, order: newOrder });
  };

  const handleSortByTime = (column) => {
    const newOrder = sort.order === "ascending" ? "descending" : "ascending";
    let sortList = [...usersList];
    if (newOrder === "ascending") {
      sortList.sort((a, b) => new Date(a[column]) - new Date(b[column]));
    }
    if (newOrder === "descending") {
      sortList.sort((a, b) => new Date(b[column]) - new Date(a[column]));
    }
    setUsersList(sortList);
    setSort({ column, order: newOrder });
  };

  const UsersList = () => {
    return usersList.map((item, index) => {
      return (
        <Table.Row
          key={index}
          active={selectedUser.userId === item.userId}
          onClick={() => setSelectedUser(item)}
        >
          <Table.Cell>
            {moment(new Date(item.createdAt)).format("LLL")}
          </Table.Cell>
          <Table.Cell>{item.email}</Table.Cell>
          <Table.Cell>{item.role}</Table.Cell>
          <Table.Cell>{getStatusColumn(item.status)}</Table.Cell>
          <Table.Cell>
            {item.lastLogin
              ? moment(new Date(item.lastLogin)).format("LLL")
              : "-"}
          </Table.Cell>
        </Table.Row>
      );
    });
  };
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        height: "calc(100% - 50px)",
        position: "absolute",
        backgroundColor: "#BFBFBF",
      }}
    >
      <Alert error message={errorMessage} open={errorMessage} />
      <Alert success message={successMessage} open={successMessage} />
      <div
        style={{
          maxWidth: "1000px",
          width: "100%",
          backgroundColor: "#F1F1F1",
          borderRadius: "10px",
          padding: "20px",
          zIndex: "1",
          marginTop: "-50px",
          display: "flex",
          justifyContent: "center",
        }}
      >
        {initializing && <Loader content="Loading..." active inline />}
        {!initializing && (
          <div style={{ width: "100%" }}>
            <Header content="Users" style={{ textAlign: "center" }} />
            <Table selectable fixed sortable>
              <Table.Header>
                <Table.HeaderCell
                  sorted={sort.column === "createdAt" ? sort.order : null}
                  onClick={() => handleSortByTime("createdAt")}
                >
                  Created
                </Table.HeaderCell>
                <Table.HeaderCell
                  sorted={sort.column === "email" ? sort.order : null}
                  onClick={() => handleSort("email")}
                >
                  Email
                </Table.HeaderCell>
                <Table.HeaderCell
                  sorted={sort.column === "role" ? sort.order : null}
                  onClick={() => handleSort("role")}
                >
                  Role
                </Table.HeaderCell>
                <Table.HeaderCell
                  sorted={sort.column === "status" ? sort.order : null}
                  onClick={() => handleSort("status")}
                >
                  Status
                </Table.HeaderCell>
                <Table.HeaderCell
                  sorted={sort.column === "lastLogin" ? sort.order : null}
                  onClick={() => handleSortByTime("lastLogin")}
                >
                  Last Login
                </Table.HeaderCell>
              </Table.Header>
              <Table.Body>
                <UsersList />
              </Table.Body>
            </Table>
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <Popup
                on="click"
                content={
                  <Button
                    secondary
                    content="Really Delete?"
                    onClick={handleDeleteUser}
                  />
                }
                trigger={
                  <Button
                    title="Permanently delete the user."
                    disabled={getEditDisabled()}
                    size="large"
                    icon="ban"
                    content="Delete"
                    color="red"
                  />
                }
              />
              <Button
                title="Disabled users are unable to login."
                disabled={getEditDisabled()}
                size="large"
                icon="minus"
                content="Disable"
                color="orange"
                onClick={handleDisableUser}
              />
              <Button
                disabled={getEditDisabled()}
                size="large"
                icon="edit"
                content="Edit"
                primary
                onClick={handleSetEditUser}
              />
              <Button
                size="large"
                icon="plus"
                content="Add"
                positive
                onClick={handleSetAddUser}
              />
            </div>
          </div>
        )}
        {!initializing && (
          <PageModal
            noScroll
            heading={mode === "add" ? "Add User" : "Edit User"}
            open={isAddEditUser}
            setOpen={setAddEditUser}
            actions={
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <Button
                  size="large"
                  content="Close"
                  onClick={() => setAddEditUser(false)}
                />
                <Button
                  loading={mutating}
                  disabled={mutating}
                  size="large"
                  content={mode === "add" ? "Add" : "Update"}
                  positive
                  onClick={mode === "add" ? handleAddUser : handleUpdateUser}
                />
              </div>
            }
          >
            <AddEditUser
              selectedUser={selectedUser}
              onChangeField={handleChangeField}
              circuitsList={circuitsList}
            />
          </PageModal>
        )}
      </div>
    </div>
  );
};
export default Users;
