import { Form } from "semantic-ui-react";

const AddEditUser = (props) => {
  const { selectedUser, onChangeField, circuitsList } = props;
  return (
    <Form size="large">
      <Form.Input
        id="email"
        value={selectedUser.email || ""}
        label="Email"
        placeholder="Email"
        onChange={onChangeField}
      />
      <Form.Select
        id="role"
        value={selectedUser.role || ""}
        label="Role"
        placeholder="Select Role"
        options={[
          { text: "Admin", value: "Admin" },
          { text: "Studio", value: "Studio" },
          { text: "Exhibitor", value: "Exhibitor" },
        ]}
        onChange={onChangeField}
      />
      {selectedUser.role === "Exhibitor" && (
        <Form.Select
          search
          id="circuitId"
          value={selectedUser.circuitId}
          label="Circuit"
          placeholder="Select Circuit"
          options={circuitsList}
          onChange={onChangeField}
        />
      )}
    </Form>
  );
};
export default AddEditUser;
