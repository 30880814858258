import { useEffect, useState, useRef } from "react";
import { CAMPAIGNS, THEATERS } from "../graphQueries";
import { useLazyQuery } from "@apollo/client";
import {
  Form,
  Header,
  DimmerDimmable,
  Button,
  Dropdown,
  Icon,
} from "semantic-ui-react";
import Downloader from "./Downloader";
import DatePicker from "react-datepicker";
import moment from "moment";
import "react-datepicker/dist/react-datepicker.css";
import "./style.css";

const Builder = () => {
  const [campaign, setCampaign] = useState({});
  const [generating, setGenerating] = useState(false);
  const [formFields, setFormFields] = useState({
    eventName: "",
    eventDate: "",
    eventTheater: "",
    eventLocation: "",
    eventRsvp: "",
    eventContact: "",
    eventFont: "Lato",
  });
  const [isDownloader, setDownloader] = useState(false);
  const [searchResults, setResults] = useState([]);
  const [searchDisabled, setSearchDisabled] = useState(false);
  const [isMobile, setMobile] = useState(false);
  const [aspectRatio, setAspect] = useState(0);
  const fontOptions = [
    {
      text: "Default",
      value: "Lato",
      content: <span style={{ fontFamily: "Lato" }}>Default</span>,
    },
    {
      text: "IBM Plex Mono",
      value: "IBM Plex Mono",
      content: (
        <span style={{ fontFamily: "IBM Plex Mono" }}>IBM Plex Mono</span>
      ),
    },
    {
      text: "Josefin Sans",
      value: "Josefin Sans",
      content: <span style={{ fontFamily: "Josefin Sans" }}>Josefin Sans</span>,
    },
    {
      text: "Oswald",
      value: "Oswald",
      content: <span style={{ fontFamily: "Oswald" }}>Oswald</span>,
    },
    {
      text: "Playfair Display",
      value: "Playfair Display",
      content: (
        <span style={{ fontFamily: "Playfair Display" }}>Playfair Display</span>
      ),
    },
    {
      text: "Playwrite US Modern",
      value: "Playwrite US Modern",
      content: (
        <span style={{ fontFamily: "Playwrite US Modern" }}>
          Playwrite US Modern
        </span>
      ),
    },
    {
      text: "Rouge Script",
      value: "Rouge Script",
      content: <span style={{ fontFamily: "Rouge Script" }}>Rouge Script</span>,
    },
    {
      text: "Rubik Doodle Triangles",
      value: "Rubik Doodle Triangles",
      content: (
        <span style={{ fontFamily: "Rubik Doodle Triangles" }}>
          Rubik Doodle Triangles
        </span>
      ),
    },
  ];
  const timerRef = useRef(null);
  useEffect(() => {
    const queryString = window.location.search;
    console.log(queryString);
    const urlParams = new URLSearchParams(queryString);
    const name = urlParams.get("name");
    console.log(name);
    campaigns({ variables: { name } });
  }, []);

  useEffect(() => {
    window.addEventListener("resize", handleResizeEvent);
    handleResizeEvent();
    return () => {
      window.removeEventListener("resize", handleResizeEvent);
    };
  }, []);

  useEffect(() => {
    console.log(formFields);
  }, [formFields]);

  useEffect(() => {
    const img = new Image();
    img.src = campaign.backgroundImageLink;
    img.onload = () => handleImageDimensions(img.width, img.height);
    console.log(img);
  }, [campaign]);

  useEffect(() => {
    clearTimeout(timerRef.current);
    timerRef.current = setTimeout(() => {
      if (!searchDisabled) handleTheaterSearch();
    }, 500);
  }, [formFields.eventTheater]);

  useEffect(() => {
    console.log("searchResults", searchResults);
  }, [searchResults]);

  const handleImageDimensions = (w, h) => {
    console.log(w, h);
    console.log(w / h);
    setAspect(w / h);
  };

  const handleResizeEvent = () => {
    if (window.innerWidth < 768) {
      setMobile(true);
    } else {
      setMobile(false);
    }
  };

  const [campaigns] = useLazyQuery(CAMPAIGNS, {
    fetchPolicy: "network-only",
    onCompleted: (result) => handleCampaigns_Result(result),
    onError: (error) => handleCampaigns_Error(error),
  });

  const handleCampaigns_Result = (result) => {
    console.log(result);
    if (result.campaigns) {
      setCampaign(result.campaigns[0]);
    }
  };

  const handleCampaigns_Error = (error) => {
    console.log(error);
  };

  const handleChangeFormField = (e, data) => {
    setSearchDisabled(false);
    let key = data.id;
    let value = e.target.value;
    if (key === "eventName") {
      if (value.length > 69) return;
    }
    if (key === "eventLocation") {
      if (value.length > 69) return;
    }
    if (key === "eventFont") {
      value = data.value;
    }
    const obj = { ...formFields };
    Object.assign(obj, { [key]: value });
    setFormFields(obj);
  };

  const handleGenerate = () => {
    setGenerating(true);
    setTimeout(() => {
      setDownloader(true);
      setGenerating(false);
    }, 500);
  };

  const handleChangeDateTime = (date) => {
    console.log(date);
    let obj = { ...formFields };
    Object.assign(obj, { eventDate: moment(date).format("LLL") });
    setFormFields(obj);
  };

  const handleChangeRsvpDate = (date) => {
    console.log(date);
    let obj = { ...formFields };
    Object.assign(obj, { eventRsvp: moment(date).format("LL") });
    setFormFields(obj);
  };

  const handleTheaterSearch = () => {
    if (formFields.eventTheater.length > 2) {
      theaters({
        variables: {
          searchTerm: formFields.eventTheater,
          circuitId: campaign.circuitId,
        },
      });
    } else {
      setResults([]);
    }
  };

  const [theaters] = useLazyQuery(THEATERS, {
    fetchPolicy: "network-only",
    onCompleted: (result) => handleTheaters_Result(result),
    onError: (error) => handleTheaters_Error(error),
  });

  const handleTheaters_Result = (result) => {
    console.log(result);
    if (result.theaters.length) {
      let newArray = result.theaters.map((item) =>
        Object.assign({}, item, {
          title: item.name,
          description: item.location,
        })
      );
      console.log("newArray", newArray);
      setResults(newArray);
    } else {
      setResults([]);
    }
  };

  const handleTheaters_Error = (error) => {
    console.log(error);
  };

  const handleResultSelect = (item) => {
    setSearchDisabled(true);
    let obj = { ...formFields };
    Object.assign(obj, {
      eventTheater: item.title,
      eventLocation: item.location,
    });
    setFormFields(obj);
    setResults([]);
  };

  const SearchResults = () => {
    return searchResults.map((item, index) => {
      return (
        <Dropdown.Item key={index} onClick={() => handleResultSelect(item)}>
          <Header>
            {item.name}
            <Header.Subheader>{item.location}</Header.Subheader>
          </Header>
        </Dropdown.Item>
      );
    });
  };

  return (
    <DimmerDimmable
      dimmed={isDownloader}
      style={{
        backgroundColor: "#888888",
        position: "absolute",
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: isMobile ? "column" : "row",
      }}
    >
      <div
        style={{
          backgroundColor: campaign.backgroundColor,
          height: isMobile ? window.innerWidth / aspectRatio : "100%",
          width: isMobile ? "100%" : "calc(100% - 400px)",
          backgroundImage: `url(${campaign.backgroundImageLink})`,
          backgroundRepeat: "no-repeat",
          backgroundPosition: "left top",
          backgroundSize: campaign.backgroundSize || "contain",
        }}
      ></div>
      <div
        style={{
          backgroundColor: campaign.backgroundColor || "#F1F1F1",
          height: "100%",
          width: isMobile ? "100%" : "400px",
          padding: "20px",
          overflowY: "auto",
          overflowX: "hidden",
        }}
      >
        <Header
          className="extra-bold"
          as="h2"
          style={{
            textAlign: "center",
            color: campaign.textColor,
            margin: "20px",
          }}
          content="Customize Your Invitation"
        />
        <Form size="large" style={{ marginBottom: "20px" }}>
          <Form.Input
            id="eventName"
            label={
              <span
                style={{
                  color: campaign.textColor,
                  fontWeight: 800,
                }}
              >
                Event Name
              </span>
            }
            placeholder="My Event Name"
            value={formFields.eventName || ""}
            onChange={handleChangeFormField}
          />
          <Form.Field>
            <label>
              <span
                style={{
                  color: campaign.textColor,
                  fontWeight: 800,
                }}
              >
                Event Date
              </span>
            </label>
            <DatePicker
              showTimeSelect
              timeIntervals={15}
              value={formFields.eventDate}
              placeholderText="Select a Date / Time"
              onChange={handleChangeDateTime}
            />
          </Form.Field>
          <Form.Field>
            <label>
              <span
                style={{
                  color: campaign.textColor,
                  fontWeight: 800,
                }}
              >
                Event Theater
              </span>
            </label>
            <Dropdown
              icon={
                <Icon name="search" style={{ margin: "0px", padding: "0px" }} />
              }
              selection
              search
              scrolling
              id="eventTheater"
              placeholder="Search for Your Theater"
              searchQuery={formFields.eventTheater || ""}
              onSearchChange={handleChangeFormField}
              open={searchResults && searchResults.length > 0}
              action={<Button content="Search" onClick={handleTheaterSearch} />}
            >
              <Dropdown.Menu>
                <SearchResults />
              </Dropdown.Menu>
            </Dropdown>
          </Form.Field>
          <Form.Input
            id="eventLocation"
            label={
              <span
                style={{
                  color: campaign.textColor,
                  fontWeight: 800,
                }}
              >
                Theater Location
              </span>
            }
            placeholder="City, State, Zip Code"
            value={formFields.eventLocation || ""}
            onChange={handleChangeFormField}
          />
          <Form.Field>
            <label>
              <span
                style={{
                  color: campaign.textColor,
                  fontWeight: 800,
                }}
              >
                RSVP Date
              </span>
            </label>
            <DatePicker
              value={formFields.eventRsvp}
              placeholderText="Select a Date"
              onChange={handleChangeRsvpDate}
            />
          </Form.Field>
          <Form.Input
            id="eventContact"
            label={
              <span
                style={{
                  color: campaign.textColor,
                  fontWeight: 800,
                }}
              >
                RSVP Contact Info
              </span>
            }
            placeholder="Email, Phone Number, Contact Name, etc."
            value={formFields.eventContact || ""}
            onChange={handleChangeFormField}
          />
          <Form.Field>
            <label>
              <span
                style={{
                  color: campaign.textColor,
                  fontWeight: 800,
                }}
              >
                Font Style
              </span>
            </label>
            <Dropdown
              style={{ fontFamily: formFields.eventFont }}
              id="eventFont"
              selection
              options={fontOptions}
              value={formFields.eventFont || ""}
              onChange={handleChangeFormField}
            />
          </Form.Field>
        </Form>
        <Button
          className="custom-button"
          style={{
            backgroundColor: campaign.buttonColor,
          }}
          loading={generating}
          disabled={generating}
          size="large"
          fluid
          primary
          content="Generate"
          onClick={handleGenerate}
        />
      </div>
      {isDownloader && (
        <Downloader
          campaign={campaign}
          formFields={formFields}
          setDownloader={setDownloader}
        />
      )}
    </DimmerDimmable>
  );
};
export default Builder;
