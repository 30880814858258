import { useState, useEffect, useRef } from "react";
import {
  Header,
  Modal,
  Button,
  Image,
  Loader,
  Dimmer,
  Form,
  Dropdown,
  Icon,
} from "semantic-ui-react";
import { CREATE_PASS, VALIDATE_PASS } from "./graphQueries";
import { useMutation } from "@apollo/client";
import jwtDecode from "jwt-decode";
import { AuthContext } from "./AuthContext";
import Login from "./Login/Login";
import moment from "moment";
import MenuItems from "./MenuItems/MenuItems";
import Home from "./Home/Home";
import Campaigns from "./Campaigns/Campaigns";
import Users from "./Users/Users";

const Dashboard = () => {
  const [loginContext, setLoginContext] = useState({
    userId: "",
    authenticated: false,
    email: "",
    role: "",
  });
  const [authenticated, setAuth] = useState(false);
  const [isReady, setReady] = useState(false);
  const [email, setEmail] = useState("");
  const [passcode, setPass] = useState([]);
  const [initializing, setInit] = useState(true);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setError] = useState("");
  const [activeMenu, setActiveMenu] = useState("Home");
  const [themeColor, setThemeColor] = useState("#5CA2D6");
  const [isRemember, setRemember] = useState(false);

  useEffect(() => {
    const queryString = window.location.search;
    console.log(queryString);
    const urlParams = new URLSearchParams(queryString);
    const email = urlParams.get("id");
    console.log(email);
    if (email) {
      setEmail(email);
      const url = new URL(window.location.href);
      url.search = "";
      window.history.pushState({}, "", url);
    }
  }, []);

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      handleCreateLoginContext(token);
    } else {
      handleInit();
    }
  }, []);

  useEffect(() => {
    const storedEmail = localStorage.getItem("kioskLogin");
    if (storedEmail) {
      setEmail(storedEmail);
      setRemember(true);
    }
  }, []);

  const handleChange = (e, data) => {
    setError("");
    const key = data.id;
    const value = data.value;
    if (key === "email") {
      setEmail(value);
    }
  };

  useEffect(() => {
    console.log(passcode);
  }, [passcode]);

  const handleChangePasscode = (value) => {
    const isNum = /^\d+$/.test(value);
    if (!isNum && value.length > 0) {
      setError("Passcode includes only digits 0-9.");
      return;
    }
    setPass(value);
  };

  const handleCreatePass = () => {
    if (!email) return;
    if (isRemember) {
      localStorage.setItem("kioskLogin", email);
    } else {
      localStorage.removeItem("kioskLogin");
    }
    setError("");
    setLoading(true);
    createPass({ variables: { email } });
  };

  const [createPass] = useMutation(CREATE_PASS, {
    onCompleted: (result) => handleCreatePass_Result(result),
    onError: (error) => handleCreatePass_Error(error),
  });

  const handleCreatePass_Result = (result) => {
    console.log(result);
    if (result.createPass && result.createPass.status === "succeeded") {
      setReady(true);
      setLoading(false);
    }
  };

  const handleCreatePass_Error = (error) => {
    console.log(error.message);
    setLoading(false);
    setError(error.message);
  };

  const handleValidatePass = () => {
    if (passcode.length !== 6) return;
    setError("");
    setLoading(true);
    validatePass({ variables: { email, passcode } });
  };

  const [validatePass] = useMutation(VALIDATE_PASS, {
    onCompleted: (result) => handleValidatePass_Result(result),
    onError: (error) => handleValidatePass_Error(error),
  });

  const handleValidatePass_Result = (result) => {
    console.log(result);
    if (result.validatePass && result.validatePass.token) {
      localStorage.setItem("token", result.validatePass.token);
      handleCreateLoginContext(result.validatePass.token);
      setLoading(false);
      setPass("");
    } else {
      setLoading(false);
      setError(result.validatePass.message);
    }
  };

  const handleValidatePass_Error = (error) => {
    console.log(error);
    setLoading(false);
    setError(error.message);
  };

  const handleCreateLoginContext = (token) => {
    const decoded = jwtDecode(token);
    console.log(decoded);
    console.log(moment.unix(decoded.exp).format("LLL"));
    const isExpired = moment.unix(decoded.exp) < moment();
    if (isExpired) {
      localStorage.removeItem("token");
      handleInit();
      return;
    }
    console.log("isExpired", isExpired);
    setLoginContext({
      userId: decoded.userId,
      authenticated: decoded.authenticated,
      email: decoded.email,
      exp: decoded.exp,
      role: decoded.role,
    });
    setAuth(true);
    handleInit();
  };

  const handleInit = () => {
    setTimeout(() => {
      setInit(false);
    }, 1000);
  };

  const handleLogout = () => {
    localStorage.removeItem("token");
    setAuth(false);
    setLoginContext({});
    setReady(false);
    handleInit();
  };

  const handleGoHome = () => {
    window.location.reload();
  };

  const handleChangeMenu = (menuItem) => {
    setActiveMenu(menuItem);
  };

  return (
    <AuthContext.Provider value={{ loginContext, themeColor }}>
      <div>
        {!authenticated && !initializing && (
          <Image
            src="./images/the-kiosk-logo.png"
            style={{
              position: "absolute",
              width: "200px",
              top: "10px",
              left: "10px",
              zIndex: 1,
            }}
          />
        )}
        {!authenticated && !initializing && (
          <div
            style={{
              position: "absolute",
              bottom: "2px",
              left: "5px",
              zIndex: 1,
            }}
          >
            <span style={{ color: "#FFFFFF", fontSize: "10px" }}>
              &copy; {moment().format("YYYY")} PaperAirMedia. All Rights
              Reserved.
            </span>
          </div>
        )}
        {authenticated && !initializing && (
          <MenuItems
            handleChangeMenu={handleChangeMenu}
            handleGoHome={handleGoHome}
            handleLogout={handleLogout}
            authenticated={authenticated}
            activeMenu={activeMenu}
          />
        )}
        {authenticated && !initializing && activeMenu === "Home" && <Home />}
        {authenticated && !initializing && activeMenu === "Campaigns" && (
          <Campaigns />
        )}
        {authenticated && !initializing && activeMenu === "Users" && <Users />}
        {!authenticated && !initializing && (
          <Login
            email={email}
            onChange={handleChange}
            handleCreatePass={handleCreatePass}
            isReady={isReady}
            passcode={passcode}
            handleValidatePass={handleValidatePass}
            loading={loading}
            errorMessage={errorMessage}
            isRemember={isRemember}
            setRemember={setRemember}
            onChangePasscode={handleChangePasscode}
          />
        )}
        {initializing && (
          <Dimmer active>
            <Loader size="large">
              <Image
                src="./images/the-kiosk-logo.png"
                style={{ width: "200px" }}
              />
            </Loader>
          </Dimmer>
        )}
      </div>
    </AuthContext.Provider>
  );
};
export default Dashboard;
