import { Modal, TransitionablePortal, Icon } from "semantic-ui-react";
import { AuthContext } from "../AuthContext";
import { useContext } from "react";

const PageModal = (props) => {
  const { themeColor } = useContext(AuthContext);
  const {
    open,
    setOpen,
    heading,
    fullscreen,
    size,
    noScroll,
    styles,
    clearImages,
  } = props;

  const handleClose = () => {
    setTimeout(() => document.body.classList.remove("modal-fade-in"), 0);
    setOpen(false);
    if (clearImages) {
      clearImages();
    }
  };

  return (
    <div>
      <style>{`
            .ui.dimmer {
              transition: background-color 0.5s ease;
              background-color: transparent !important;
            }
  
            .modal-fade-in .ui.dimmer {
              background-color: rgba(0,0,0,0.8) !important;
            }
          `}</style>

      <TransitionablePortal
        open={open}
        onOpen={() =>
          setTimeout(() => document.body.classList.add("modal-fade-in"), 0)
        }
        transition={{ animation: "scale", duration: 500 }}
      >
        <Modal
          closeOnDimmerClick={false}
          closeOnDocumentClick={false}
          size={size}
          style={{ width: fullscreen ? "90%" : "" }}
          id="pageModal"
          open={true}
        >
          <Modal.Header
            style={{
              backgroundColor: themeColor,
              color: "#FFFFFF",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            {heading}
            <Icon
              className="custom-button"
              name="close"
              inverted
              onClick={handleClose}
            />
          </Modal.Header>
          <Modal.Content
            style={{
              ...styles,
              backgroundColor: "#f1f1f1",
            }}
            scrolling={!noScroll}
          >
            {props.children}
          </Modal.Content>
          <Modal.Actions>{props.actions}</Modal.Actions>
        </Modal>
      </TransitionablePortal>
    </div>
  );
};

export default PageModal;
