import { useContext, useEffect, useState } from "react";
import { AuthContext } from "../AuthContext";
import { Header, Image, Table } from "semantic-ui-react";
import { DOWNLOADS } from "../graphQueries";
import { useLazyQuery } from "@apollo/client";
const Home = () => {
  const { loginContext, themeColor } = useContext(AuthContext);
  const isAdmin = loginContext.role === "Admin";
  const [downloadsList, setDownloads] = useState([]);

  useEffect(() => {
    downloads();
  }, []);

  const [downloads] = useLazyQuery(DOWNLOADS, {
    fetchPolicy: "network-only",
    onCompleted: (result) => handleDownloads_Result(result),
    onError: (error) => handleDownloads_Error(error),
  });

  const handleDownloads_Result = (result) => {
    if (result.downloads) {
      setDownloads(result.downloads);
    }
  };

  const handleDownloads_Error = (error) => {
    console.log(error);
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        height: "calc(100% - 50px)",
        position: "absolute",
        backgroundColor: "#BFBFBF",
      }}
    >
      <div
        style={{
          maxWidth: "500px",
          width: "100%",
          backgroundColor: "#F1F1F1",
          borderRadius: "10px",
          padding: "20px",
          zIndex: "1",
          marginTop: "-50px",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center",
            marginBottom: "20px",
          }}
        >
          <Image src="/images/the-kiosk-logo.png" size="small" />
          <Header
            content="Welcome to The Kiosk!"
            style={{ textAlign: "center" }}
            subheader="A Custom Invitation Generator by PaperAirplane."
          />
        </div>
        <div
          style={{
            backgroundColor: "#FFFFFF",
            // padding: "20px",
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <div
            style={{
              width: "150px",
              height: "100px",
              backgroundColor: themeColor,
              color: "#FFFFFF",
              borderRadius: "10px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              margin: "20px",
            }}
          >
            <span style={{ fontSize: "3em", margin: "10px" }}>
              {downloadsList.length || 0}
            </span>
            <div style={{ textAlign: "center" }}>Total Downloads</div>
          </div>
          <div style={{ margin: "20px" }}>
            <p>
              To get started, select Campaigns from the menu, a create a new
              Campaign for an upcoming title.
            </p>
            <p>
              The Campaign URL can then be distributed to the customer, who can
              customize and download their invitations.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Home;
