import { Form, Header, Button, Message, Checkbox } from "semantic-ui-react";
import Countdown, { zeroPad } from "react-countdown";
import moment from "moment";
import { render } from "react-dom";
import { useEffect, useState, useContext } from "react";
import OtpInput from "react-otp-input";
import { AuthContext } from "../AuthContext";
const Login = (props) => {
  const {
    email,
    onChange,
    handleCreatePass,
    isReady,
    passcode,
    handleValidatePass,
    loading,
    errorMessage,
    isRemember,
    setRemember,
    onChangePasscode,
  } = props;
  const { loginContext, themeColor } = useContext(AuthContext);
  const [countdown, setCountdown] = useState(Date.now() + 600000);
  const renderer = ({ hours, minutes, seconds, completed }) => {
    if (completed) {
      // Render a completed state
      return <p>Code is expired. Login again to receive a new code.</p>;
    } else {
      // Render a countdown
      return (
        <span>
          {zeroPad(minutes)}:{zeroPad(seconds)}
        </span>
      );
    }
  };
  const handleRemember = () => {
    setRemember(!isRemember);
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        width: "100%",
        position: "absolute",
        backgroundColor: "#272727",
      }}
    >
      <div
        style={{
          maxWidth: "500px",
          width: "100%",
          backgroundColor: "#F1F1F1",
          borderRadius: "10px",
          padding: "20px",
          zIndex: "1",
          marginTop: "-50px",
        }}
      >
        <Form size="large">
          <Header
            content="The Kiosk"
            subheader="Asset Generator"
            style={{ textAlign: "center" }}
          />
          {!isReady && (
            <Form.Input
              id="email"
              label="Email"
              placeholder="Email"
              disabled={loading}
              value={email}
              onChange={onChange}
            />
          )}
          {!isReady && (
            <Form.Field>
              <Checkbox
                checked={isRemember}
                onChange={handleRemember}
                label="Remember Me"
              />
            </Form.Field>
          )}
          {!isReady && (
            <Form.Field>
              <Button
                className="custom-button"
                style={{ backgroundColor: themeColor }}
                size="large"
                content="Login"
                loading={loading}
                disabled={loading}
                primary
                fluid
                onClick={handleCreatePass}
              />
            </Form.Field>
          )}
          {isReady && (
            <p>
              Your One-Time Passcode has been sent to your email address. Enter
              the passcode here to login. Code will expire in 10 minutes.
            </p>
          )}
          {isReady && (
            <p style={{ textAlign: "center" }}>
              <Countdown date={countdown} renderer={renderer} />
            </p>
          )}
          {isReady && (
            <div>
              <OtpInput
                containerStyle={{
                  justifyContent: "space-between",
                  margin: "20px",
                }}
                inputStyle={{
                  padding: "00px",
                  width: "50px",
                  height: "50px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  fontSize: "2em",
                  color: themeColor,
                }}
                value={passcode}
                onChange={onChangePasscode}
                numInputs={6}
                renderSeparator={<span>-</span>}
                renderInput={(props) => <input {...props} />}
                shouldAutoFocus
              />
            </div>
          )}
          {isReady && (
            <Form.Field>
              <Button
                className="custom-button"
                style={{ backgroundColor: themeColor }}
                size="large"
                content="Submit"
                loading={loading}
                disabled={loading}
                primary
                fluid
                onClick={handleValidatePass}
              />
            </Form.Field>
          )}
          {errorMessage && <Message visible error content={errorMessage} />}
        </Form>
      </div>
    </div>
  );
};
export default Login;
