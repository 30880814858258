import { useContext } from "react";
import { Icon, Dropdown, Header, Menu } from "semantic-ui-react";
import { AuthContext } from "../AuthContext";
const MenuItems = (props) => {
  const { loginContext, themeColor } = useContext(AuthContext);
  const {
    activeMenu,
    authenticated,
    handleChangeMenu,
    handleGoHome,
    handleLogout,
  } = props;
  const isAuthenticatedUser = loginContext.authenticated;
  const isAdmin = loginContext.role === "Admin";
  return (
    <Menu
      inverted
      size="huge"
      style={{
        height: "50px",
        borderRadius: "0px",
        backgroundColor: themeColor,
        margin: "0px",
      }}
    >
      <Menu.Menu position="left">
        <Menu.Item
          active={activeMenu === "Home"}
          onClick={
            authenticated ? () => handleChangeMenu("Home") : handleGoHome
          }
        >
          Home
        </Menu.Item>

        {isAuthenticatedUser && isAdmin && (
          <Menu.Item
            active={activeMenu === "Users"}
            onClick={() => handleChangeMenu("Users")}
          >
            Users
          </Menu.Item>
        )}

        {isAuthenticatedUser && (
          <Menu.Item
            active={activeMenu === "Campaigns"}
            onClick={() => handleChangeMenu("Campaigns")}
          >
            Campaigns
          </Menu.Item>
        )}
      </Menu.Menu>

      <Menu.Menu position="right">
        <Dropdown
          direction="left"
          icon={null}
          trigger={
            <Menu.Item>
              <Icon name="user" inverted />
            </Menu.Item>
          }
        >
          <Dropdown.Menu>
            <Dropdown.Item onClick={handleLogout}>Logout</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </Menu.Menu>
    </Menu>
  );
};
export default MenuItems;
